export const cities = [
  'Amsterdam',
  'Almere',
  'Rotterdam',
  'Utrecht',
  'Den-Haag',
  'Eindhoven',
  'Tilburg',
  'Groningen',
] as const;
