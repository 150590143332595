import React, { FC, useEffect, useState } from 'react';
import 'twin.macro';

type TabListProps = {
  defaultTab?: string;
  tabs: {
    name: string;
    id?: string;
    onClick: () => void;
  }[];
};

export const TabList: FC<TabListProps> = ({ tabs, defaultTab, ...rest }) => {
  const [activeTab, setActiveTab] = useState(defaultTab || tabs[0].name);

  useEffect(() => {
    // check if query has param activeTab
    const urlParams = new URLSearchParams(window.location.search);
    const activeTab = urlParams.get('activeTab');
    if (activeTab) {
      if (tabs.find((tab) => tab.name === activeTab)) setActiveTab(activeTab);
    }
  }, [tabs]);

  return (
    <div role="tablist" className="tabs tabs-bordered" tw="w-full" {...rest}>
      {tabs.map((tab) => {
        const identifier = tab?.id || tab.name;

        return (
          <button
            role="tab"
            className={`tab ${activeTab === identifier ? 'tab-active' : ''}`}
            onClick={() => {
              setActiveTab(identifier);
              tab.onClick();
            }}
          >
            {tab.name}
          </button>
        );
      })}
    </div>
  );
};
