/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ComponentProps, FC, PropsWithChildren } from 'react';
import { Sidebar } from '../Sidebar/Sidebar';
import { UserBar } from '../UserBar/UserBar';

type CMSLayoutProps = PropsWithChildren<{
  sideBarProps: ComponentProps<typeof Sidebar>;
  userBarProps: ComponentProps<typeof UserBar>;
}>;

export const CMSLayout: FC<CMSLayoutProps> = ({
  children,
  sideBarProps,
  userBarProps,
}) => {
  return (
    <main tw="flex h-full">
      <Sidebar {...sideBarProps} />
      <div tw="w-full overflow-y-scroll">
        <UserBar {...userBarProps} />
        <section tw="px-4">{children}</section>
      </div>
    </main>
  );
};
