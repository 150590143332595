/** @jsxImportSource @emotion/react */
import 'twin.macro';
import React, { FC, Fragment } from 'react';
import { Text } from '../Text/Text';
import tw, { TwStyle } from 'twin.macro';
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';

const Container = tw.div`flex flex-col w-full h-full py-4 space-y-8`;

const VARIANTS = {
  dark: tw`bg-gray-800 text-white`,
  light: tw`bg-gray-200 text-black`,
};

type MenuItem = {
  title: string;
  items: {
    title: string;
    action?: () => void;
    isActive?: boolean;
    count?: number;
    url?: string;
  }[];
};

type SidebarProps = {
  title?: string;
  description?: string;
  linkElement?: React.ElementType;
  variant?: keyof typeof VARIANTS;
  menuItems: MenuItem[];
};

export const Sidebar: FC<SidebarProps> = ({
  title,
  description,
  variant = Object.keys(VARIANTS)[0] as keyof typeof VARIANTS,
  menuItems,
  linkElement,
  ...rest
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  React.useEffect(() => {
    const isCollapsed = localStorage.getItem('isCollapsed');
    if (isCollapsed) {
      setIsCollapsed(isCollapsed === 'true');
    }
  }, []);

  const toggleCollapse = () => {
    localStorage.setItem('isCollapsed', String(!isCollapsed));
    setIsCollapsed(!isCollapsed);
  };
  const Icon = isCollapsed ? ArrowRightCircleIcon : ArrowLeftCircleIcon;
  const variantStyle = VARIANTS[variant];
  return (
    <Container
      css={[
        variantStyle,
        isCollapsed
          ? tw`max-w-[30px] px-0 overflow-hidden`
          : tw`max-w-[170px] px-8`,
      ]}
      {...rest}
    >
      <div tw="relative">
        <Text variant="title" css={[isCollapsed ? tw`hidden` : tw``]}>
          {title}
        </Text>
        <Text variant="sub" css={[isCollapsed ? tw`hidden` : tw``]}>
          {description}
        </Text>
        <Icon
          tw="w-6 h-6 cursor-pointer absolute right-0 top-1/2 -translate-y-1/2"
          onClick={toggleCollapse}
        />
      </div>

      {menuItems?.map((menu, index) => (
        <Fragment key={index}>
          {index !== 0 && index !== menuItems.length && (
            <div className="divider"></div>
          )}
          <MenuItem
            item={menu}
            linkElement={linkElement}
            css={[isCollapsed ? tw`hidden` : tw``]}
          />
        </Fragment>
      ))}
    </Container>
  );
};

type MenuItemProps = {
  item: MenuItem;
  linkElement?: SidebarProps['linkElement'];
  css?: TwStyle[];
};
const findActiveUrl = (url: string) => window?.location?.pathname === url;

const MenuItem: FC<MenuItemProps> = ({ item, linkElement, ...rest }) => {
  return (
    <div tw="space-y-4" {...rest}>
      <Text variant="sub">{item.title}</Text>
      <ul tw="space-y-4">
        {item.items.map((subItem, index) => (
          <li
            key={index}
            tw="transition-all duration-300 cursor-pointer hover:(bg-blue-800 rounded-md py-1 px-2)"
            css={[
              (subItem.url && findActiveUrl(subItem.url)) || subItem.isActive
                ? tw`rounded-md bg-blue-600 py-1 px-2`
                : tw``,
            ]}
          >
            <Text
              tw="w-full flex"
              as={subItem.url ? linkElement || 'a' : 'button'}
              href={subItem.url}
              to={subItem.url}
            >
              {subItem.title}
              {subItem.count && (
                <span tw="ml-auto">
                  <div className="badge badge-info badge-md">
                    {subItem.count}
                  </div>
                </span>
              )}
            </Text>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const generateSidebarItems = (count: number): MenuItem[] => {
  return Array.from({ length: count }, (_, index) => ({
    title: `Menu ${index + 1}`,
    items: Array.from({ length: count }, (_, index) => ({
      title: `Item ${index + 1}`,
      url: `/item${index + 1}`,
      count: index === 0 ? 10 : undefined,
      isActive: index === 0,
    })),
  }));
};
