'use client';
import { Packages } from '@sal-solution/types/types/housing/types';
import React, { FC, PropsWithChildren } from 'react';

export type SubscribeContextType = {
  newSubscriber?: {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    phone: string;
    city: string;
  };
  setNewSubscriber: (
    newSubscriber: SubscribeContextType['newSubscriber']
  ) => void;
  userPackage?: Packages;
  setUserPackage: (userPackage?: Packages) => void;
};

export const SubscribeContext = React.createContext<SubscribeContextType>({
  setNewSubscriber: () => undefined,
  setUserPackage: () => undefined,
});

export const SubscribeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [newSubscriber, setNewSubscriber] =
    React.useState<SubscribeContextType['newSubscriber']>();
  const [userPackage, setUserPackage] = React.useState<Packages>();

  return (
    <SubscribeContext.Provider
      value={{ newSubscriber, setNewSubscriber, userPackage, setUserPackage }}
    >
      {children}
    </SubscribeContext.Provider>
  );
};

export const useSubscribe = () => {
  return React.useContext(SubscribeContext);
};
