/** @jsxImportSource @emotion/react */

'use client';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  split,
} from '@apollo/client';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { GlobalStyles } from 'twin.macro';
import { SubscribeProvider } from './Subscribe.context';
// import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getCookie } from '../utils/cookies';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

const uri = process.env.GRAPHQL_URL || 'http://localhost:4000/graphql';

export const httpLink = createHttpLink({
  uri: uri,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.GRAPHQL_URL_WS as string,
  })
);

export const authLink = setContext((_, { headers, ...rest }) => {
  // get the authentication token from local storage if it exists
  const foundSession = getCookie('token') || undefined;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: foundSession ? `Bearer ${foundSession}` : '',
    },
  };
});

export const createApolloLink = (link: ApolloLink) => {
  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  //  return authLink.concat(link);
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(link)
  );
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const createdApolloLink = createApolloLink(errorLink.concat(httpLink));

export const client = new ApolloClient({
  link: createdApolloLink,
  cache: new InMemoryCache(),
});

export type AppContextType = {
  isSignedIn: boolean;
  setIsSignedIn: (isSignedIn: boolean) => void;
};

const AppContext = createContext<AppContextType>({
  isSignedIn: false,
  setIsSignedIn: (_: boolean) => undefined,
});

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const token = getCookie('token');
    setIsSignedIn(!!token);
  }, []);

  return (
    <AppContext.Provider value={{ isSignedIn, setIsSignedIn }}>
      <GlobalStyles />
      <ApolloProvider client={client}>
        <SubscribeProvider>{children}</SubscribeProvider>
      </ApolloProvider>
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
